.container {
    width: 100%;
    min-height: 100vh;
    padding: var(--space-8);
    position: relative;
}

.container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:
        radial-gradient(circle at top right, rgba(86, 100, 255, 0.1), transparent 50%),
        radial-gradient(circle at bottom left, rgba(255, 86, 169, 0.05), transparent 50%),
        linear-gradient(rgba(255, 255, 255, 0.03) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
    background-size: 100% 100%, 100% 100%, 20px 20px, 20px 20px;
    z-index: -1;
}

.inner {
    max-width: var(--max-width);
    margin: 0 auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space-12);
}

.logoContainer {
    display: flex;
    align-items: center;
    gap: var(--space-3);
    transition: all 0.3s ease;
}

.logoSvg {
    height: 2.5rem;
    width: auto;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
    transition: all 0.3s ease;
}

.logoContainer:hover .logoSvg {
    transform: rotate(15deg) scale(1.1);
    filter: drop-shadow(0 4px 8px rgba(86, 100, 255, 0.2));
}

.logoText {
    font-size: var(--font-size-xl);
    font-weight: 700;
    background: linear-gradient(90deg, var(--color-primary), var(--color-secondary));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
    transition: all 0.3s ease;
}

.logoContainer:hover .logoText {
    font-size: var(--font-size-xl);
    font-weight: 700;
    background: linear-gradient(90deg, var(--color-secondary), var(--color-primary));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
}

.hero {
    display: flex;
    flex-direction: column;
    gap: var(--space-6);
    margin-bottom: var(--space-16);
}

.title {
    font-size: var(--font-size-3xl);
    font-weight: 800;
    line-height: 1.2;
    max-width: 800px;
}

.subtitle {
    font-size: var(--font-size-lg);
    color: var(--color-text-secondary);
    max-width: 600px;
}

.section {
    margin-bottom: var(--space-16);
}

.sectionTitle {
    font-size: var(--font-size-xl);
    margin-bottom: var(--space-6);
    font-weight: 700;
}

.videoGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: var(--space-6);
}

.resourceGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: var(--space-6);
}

.footer {
    text-align: center;
    padding: var(--space-8) 0;
    color: var(--color-text-tertiary);
    font-size: var(--font-size-sm);
}

@media (max-width: 768px) {
    .container {
        padding: var(--space-4);
    }

    .title {
        font-size: var(--font-size-2xl);
    }

    .subtitle {
        font-size: var(--font-size-md);
    }

    .videoGrid,
    .resourceGrid {
        grid-template-columns: 1fr;
    }

    .logoContainer {
        gap: var(--space-2);
    }

    .logoSvg {
        height: 2rem;
    }
}