.cardWrapper {
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.card {
    background-color: var(--color-surface);
    border-radius: var(--border-radius-md);
    overflow: hidden;
    border: 1px solid var(--color-border);
    transition: all var(--transition-normal);
    color: var(--color-text-primary);
    display: block;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.card:hover {
    transform: translateY(-6px);
    border-color: var(--color-primary);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.thumbnailContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
}

.thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: var(--color-surface-accent);
    transition: transform var(--transition-normal);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 60%);
    opacity: 0;
    transition: opacity var(--transition-normal);
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewDetails {
    color: white;
    font-weight: 600;
    font-size: var(--font-size-md);
    padding: var(--space-2) var(--space-4);
    border-radius: var(--border-radius-sm);
    background-color: rgba(0, 0, 0, 0.6);
    transform: translateY(10px);
    transition: transform var(--transition-normal);
}

.card:hover .overlay {
    opacity: 1;
}

.card:hover .thumbnail {
    transform: scale(1.05);
}

.card:hover .viewDetails {
    transform: translateY(0);
}

.info {
    padding: var(--space-4);
    display: flex;
    flex-direction: column;
    height: calc(100% - 56.25%);
    /* 16:9 aspect ratio subtracted from 100% */
}

.title {
    font-size: var(--font-size-lg);
    font-weight: 600;
    margin-bottom: var(--space-2);
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.author {
    font-size: var(--font-size-sm);
    color: var(--color-primary);
    margin-bottom: var(--space-2);
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    position: relative;
}

.author:hover {
    text-decoration: underline;
}

.description {
    font-size: var(--font-size-sm);
    color: var(--color-text-secondary);
    margin-bottom: var(--space-3);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex-grow: 1;
}