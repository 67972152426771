/* VideoResources.module.css */
.container {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: var(--space-4);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space-8);
    padding-bottom: var(--space-4);
    border-bottom: 1px solid var(--color-border);
}

.backButton,
.watchButton,
.button {
    display: flex;
    align-items: center;
    padding: var(--space-2) var(--space-4);
    border-radius: var(--border-radius-md);
    font-weight: 600;
    font-size: var(--font-size-sm);
    text-decoration: none;
    transition: all var(--transition-normal);
    cursor: pointer;
    border: 1px solid;
}

.backButton {
    color: var(--color-text-secondary);
    background-color: var(--color-surface-accent);
    border-color: var(--color-border);
}

.backButton:hover {
    background-color: var(--color-surface-hover);
    transform: translateX(-2px);
}

.watchButton,
.button {
    color: var(--color-text-primary);
    background-color: var(--color-primary);
    border-color: rgba(240, 246, 252, 0.1);
}

.watchButton:hover,
.button:hover {
    background-color: var(--color-primary-hover);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(86, 100, 255, 0.2);
}

.buttonIcon {
    margin-right: var(--space-2);
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid var(--color-surface-accent);
    border-top: 4px solid var(--color-primary);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: var(--space-4);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.content {
    background-color: var(--color-surface);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    margin-bottom: var(--space-8);
}

.contentHeader {
    padding: var(--space-6);
    border-bottom: 1px solid var(--color-border);
}

.contentBody {
    padding: var(--space-6);
}

.title {
    font-size: var(--font-size-xl);
    font-weight: 600;
    margin-bottom: var(--space-2);
    color: var(--color-text-primary);
}

.description {
    font-size: var(--font-size-md);
    color: var(--color-text-secondary);
}

.markdownContent {
    color: var(--color-text-primary);
    line-height: 1.7;
}

.markdownContent h1 {
    font-size: var(--font-size-xl);
    margin: var(--space-6) 0 var(--space-4);
    padding-bottom: var(--space-2);
    border-bottom: 1px solid var(--color-border);
    color: var(--color-text-primary);
}

.markdownContent h2 {
    font-size: var(--font-size-lg);
    margin: var(--space-6) 0 var(--space-3);
    color: var(--color-text-primary);
}

.markdownContent h3 {
    font-size: var(--font-size-md);
    margin: var(--space-4) 0 var(--space-2);
    color: var(--color-text-primary);
}

.markdownContent p {
    margin-bottom: var(--space-4);
}

.markdownContent a {
    color: var(--color-primary);
    text-decoration: none;
    transition: color var(--transition-fast);
}

.markdownContent a:hover {
    color: var(--color-primary-hover);
    text-decoration: underline;
}

.markdownContent ul,
.markdownContent ol {
    margin: var(--space-2) 0 var(--space-4) var(--space-6);
}

.markdownContent li {
    margin-bottom: var(--space-2);
}

.markdownContent strong {
    color: var(--color-accent);
    font-weight: 600;
}

/* Code block styling */
.markdownContent .code-block {
    margin: var(--space-6) 0;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
    overflow: hidden;
}

.markdownContent .code-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-surface-accent);
    padding: var(--space-3) var(--space-4);
    border-bottom: 1px solid var(--color-border);
}

.markdownContent .code-block-title {
    font-size: var(--font-size-sm);
    font-weight: 600;
    color: var(--color-text-secondary);
    display: flex;
    align-items: center;
}

.markdownContent .code-block-title svg {
    margin-right: var(--space-2);
}

.markdownContent .code-actions {
    display: flex;
    gap: var(--space-2);
}

.markdownContent .code-action-button {
    background: none;
    border: none;
    color: var(--color-text-tertiary);
    cursor: pointer;
    padding: var(--space-1) var(--space-2);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-xs);
    display: flex;
    align-items: center;
    transition: all var(--transition-fast);
}

.markdownContent .code-action-button:hover {
    color: var(--color-text-primary);
    background-color: var(--color-surface-hover);
}

.markdownContent .code-action-button svg {
    margin-right: var(--space-1);
}

.markdownContent .code-block-content {
    padding: var(--space-4);
    background-color: var(--color-surface);
    overflow-x: auto;
    white-space: pre-wrap;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: var(--font-size-sm);
    color: var(--color-text-secondary);
    max-height: 600px;
    overflow-y: auto;
}

/* Special prompt container styling */
.promptContainer {
    margin: var(--space-6) 0;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
    overflow: hidden;
    background-color: var(--color-surface-accent);
    scrollbar-width: thin;
    scrollbar-color: var(--color-border) transparent;
}

.promptContainer::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.promptContainer::-webkit-scrollbar-track {
    background: transparent;
}

.promptContainer::-webkit-scrollbar-thumb {
    background-color: var(--color-border);
    border-radius: 3px;
}

.promptHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-surface-accent);
    padding: var(--space-3) var(--space-4);
    border-bottom: 1px solid var(--color-border);
}

.promptTitle {
    font-size: var(--font-size-sm);
    font-weight: 600;
    color: var(--color-text-secondary);
    display: flex;
    align-items: center;
}

.promptTitle svg {
    margin-right: var(--space-2);
}

.promptActions {
    display: flex;
    gap: var(--space-2);
}

.promptActionButton {
    background: none;
    border: none;
    color: var(--color-text-tertiary);
    cursor: pointer;
    padding: var(--space-1) var(--space-2);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-xs);
    display: flex;
    align-items: center;
    transition: all var(--transition-fast);
}

.promptActionButton:hover {
    color: var(--color-text-primary);
    background-color: var(--color-surface-hover);
}

.promptActionButton svg {
    margin-right: var(--space-1);
}

.promptContent {
    padding: var(--space-4);
    background-color: var(--color-surface);
    overflow-x: auto;
    white-space: pre-wrap;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: var(--font-size-sm);
    color: var(--color-text-secondary);
    max-height: 600px;
    overflow-y: auto;
}

.error {
    text-align: center;
    padding: var(--space-16);
}

.error h2 {
    font-size: var(--font-size-xl);
    margin-bottom: var(--space-4);
    color: var(--color-text-primary);
}

.error p {
    color: var(--color-text-secondary);
    margin-bottom: var(--space-6);
}

/* Responsive design */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-4);
    }

    .backButton,
    .watchButton {
        width: 100%;
        justify-content: center;
    }

    .contentHeader,
    .contentBody {
        padding: var(--space-4);
    }
}